/* modal.module.css */

.modal__wrap {
    position: fixed;
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    overflow-x: hidden;
    background-color: rgba(31, 32, 41, .75);
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms 700ms ease;
}

.visible {
    pointer-events: auto;
    opacity: 1;
    transition: all 300ms ease-in-out;
}

.modal {
    overflow-y: visible;
    overflow-x: visible;
    padding: 20px;
    position: relative;
    display: block;
    width: 400px;
    /*height: 60%;*/
    /*min-height: 400px;*/
    min-width: 400px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    align-self: center;
    box-shadow: 0 12px 25px 0 rgba(199, 175, 189, .25);
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
    transform: scale(0);
}
.modalExpanded {
    overflow-y: visible;
    overflow-x: visible;
    padding: 20px;
    position: relative;
    display: block;
    width: 70vw;
    /*height: 60%;*/
    min-height: 400px;
    min-width: 400px;
    margin: 0 auto;
    margin-top: -30%; /* here is the different of expanded modal */
    margin-bottom: 20px;
    border-radius: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    align-self: center;
    box-shadow: 0 12px 25px 0 rgba(199, 175, 189, .25);
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
    transform: scale(0);
}
.modalExtraExpanded {
    overflow-y: visible;
    overflow-x: visible;
    padding: 20px;
    position: relative;
    display: block;
    width: 70vw;
    /*height: 60%;*/
    min-height: 400px;
    min-width: 400px;
    margin: 0 auto;
    margin-top: -45%; /* here is the different of expanded modal */
    margin-bottom: 20px;
    border-radius: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    align-self: center;
    box-shadow: 0 12px 25px 0 rgba(199, 175, 189, .25);
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
    transform: scale(0);
}

.visible .modal {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}


.visible .modalExpanded {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}


.visible .modalExtraExpanded {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}
.profileTileInputText {
    height: 23px;
    border: 0px;
}

/* OTHER */

.circleButtonContainer {
    width: 16px;
    height: 16px;
    background: var(--light-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8.5px;
    border-radius: 20px;
}
.circleButtonContainerOrange {
    background: var(--jv-orange);
}
.circleButtonContainerGray {
    background: var(--dark-gray);
}
.circleButtonContainerBlue {
    background: var(--light-blue);
}
.circleButtonContainerEmpty {
    background: var(--lightest-gray);
}

.circleButtonContainer:hover {
    background: var(--lightest-blue);
}
.circleButtonContainerOrange:hover {
    background: var(--light-jv-orange);
}
.circleButtonContainerGray:hover {
    background: var(--gray-blue);
}
.circleButtonContainerBlue:hover {
    background: var(--lightest-blue);
}

.universalTile {
    display: inline-block;
    background-color: white;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px #12263F0D;
}

/* END OF OTHER */